<template>
  <div class="importBuilding">
    <import-template
      :uploadImgUrl="uploadImgUrl"
      :downloadUrl="downloadUrl"
      :showCloseBtn="false"
      label="房屋基本信息"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
export default {
  name: "importBuilding",
  data() {
    return {
      downloadUrl: "/static/excel/空间信息导入.xlsx",
      uploadImgUrl: "/gateway/hc-portal/import/house",
    };
  },
  components: {
    importTemplate,
  },
  mounted() {
    this.$setBreadList("房屋信息导入");
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.importBuilding {
  height: calc(100% - 98px);
  box-sizing: border-box;
  background: #fff;
  ::v-deep .content {
    margin-left: 20px;
    justify-content: flex-start;
  }
}
</style>
